export type NumberBox = {
  name?: string;
  modelValue?: number | string;
  min?: number | string;
  max?: number | string;
  step?: number | string;
  integer?: boolean;
  disabled?: boolean;
  disabledInput?: boolean;
  asyncChange?: boolean;
  inputWidth?: number | string;
  showMinus?: boolean;
  showPlus?: boolean;
  decimalLength?: number | string;
  longPress?: boolean;
  color?: string;
  buttonSize?: number | string;
  bgColor?: string;
  cursorSpacing?: number | string;
  disableMinus?: boolean;
  disablePlus?: boolean;
  iconStyle?: string;
};
export type NumberBoxPropsType = NumberBox;
export default {
  // 步进器组件
  modelValue: 0,
  min: 1,
  max: Number.MAX_SAFE_INTEGER,
  step: 1,
  integer: false,
  disabled: false,
  disabledInput: false,
  asyncChange: false,
  inputWidth: 35,
  showMinus: true,
  showPlus: true,
  longPress: true,
  color: "#323233",
  buttonSize: 30,
  bgColor: "#EBECEE",
  cursorSpacing: 100,
  disableMinus: false,
  disablePlus: false,
};
