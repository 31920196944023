export type DatetimePicker = {
	show?: boolean
	showToolbar?: boolean
	modelValue?: string | number
	value?: string | number
	title?: string
	mode?: 'date' | 'time' | 'year-month' | 'datetime'
	minDate?: number
	maxDate?: number
	minHour?: number
	maxHour?: number
	minMinute?: number
	maxMinute?: number,
	formatter?: (v: string) => string
	filter?: (t:string,v: string) => string
	loading?: boolean
	itemHeight?: string | number
	cancelText?: string
	confirmText?: string
	cancelColor?: string
	confirmColor?: string
	visibleItemCount?: string | number
	closeOnClickOverlay?: boolean
	defaultIndex?:Array<number>
};
export type DatetimePickerPropsType = Omit<DatetimePicker, "mode"> & {
	mode?: string;
};
const datetimePicker: DatetimePicker = {
	// datetimePicker组件
	show: false,
	showToolbar: true,
	mode: 'datetime',
	maxDate: new Date(new Date().getFullYear() + 10, 0, 1).getTime(),
	minDate: new Date(new Date().getFullYear() - 10, 0, 1).getTime(),
	minHour: 0,
	maxHour: 23,
	minMinute: 0,
	maxMinute: 59,
	loading: false,
	itemHeight: 44,
	cancelText: '取消',
	confirmText: '确认',
	cancelColor: '#909193',
	confirmColor: '#3c9cff',
	visibleItemCount: 5,
	closeOnClickOverlay: false,
	defaultIndex:[]
};
export default datetimePicker;
