
type ModeTypes = "fade"|"fade-zoom"|"fade-down"|"fade-left"|"fade-right"|"fade-up"|"slide-down"|"slide-left"|"slide-right"|"slide-up"|"zoom"|"zoom-in-top"

export type Transition = {
	// 是否展示组件
	show?:boolean,
	// 使用的动画模式
	mode?:ModeTypes,
	// 动画的执行时间，单位ms
	duration?:number|string,
	// 使用的动画过渡函数
	timingFunction?:string,
	customStyle?: Record<string,any> |string,
	customClass?:string
};
export type TransitionPropsType = Omit<Transition,'mode'>&{
	mode?:string
};
const transition: Transition = {
	// transition组件
	mode: "fade",
	duration: 300,
	timingFunction: "ease-out",
};
export default transition;
