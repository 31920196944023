export type Overlay = {
  show?: boolean,
  zIndex?: string|number,
  duration?: string|number,
  opacity?: string|number,
  customStyle?: Record<string,any> |string,
  customClass?:string
};
export type OverlayPropsType = Overlay
const overlay: Overlay = {
  // overlay组件
  zIndex: 10070,
  duration: 300,
  opacity: 0.5,
};
export default overlay;
