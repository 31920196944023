export type Radio = {
  name?: string | string | boolean;
  shape?: "square" | "circle";
  checkbox?: boolean;
  disabled?: boolean;
  labelDisabled?: boolean;
  activeColor?: string;
  inactiveColor?: string;
  iconSize?: string | number;
  color?: string;
  label?: string;
  labelSize?: string | number;
  labelColor?: string;
  size?: string | number;
  customStyle?: Record<string,any>|string,
  customClass?:string
};
export type RadioPropsType = Omit<Radio, "shape"> & {
  shape?: string;
};
const radio: Radio = {
};
export default radio;
