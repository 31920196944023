export type NoticeBar = {
	text?: string | string[],
	direction?: 'row'|'column',
	step?: boolean,
	icon?: string,
	mode?: 'link'|'closable',
	color?: string,
	bgColor?:string,
	speed?: string|number,
	fontSize?: string|number,
	duration?: string|number,
	disableTouch?: boolean,
	url?: string,
	linkType?: 'navigateTo'|'redirectTo'|'reLaunch'|'switchTab',
	customStyle?:string|Record<string,any>
};
export type NoticeBarPropsType = Omit<
	NoticeBar,
	"direction" | "linkType"
> & {
	direction?: string;
	linkType?: string
};
const noticeBar: NoticeBar = {
	// noticeBar组件
	text: [],
	direction: 'row',
	step: false,
	icon: 'volume',
	color: '#f9ae3d',
	bgColor: '#fdf6ec',
	speed: 80,
	fontSize: 14,
	duration: 2000,
	disableTouch: true,
	linkType: 'navigateTo'

};
export default noticeBar;


