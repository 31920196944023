
export type Option = {
	value?:string|number|boolean|Record<string,any>,
	label?:{
		type:[String, Number]
	},
	disabled?: false,
	customStyle?: Record<string,any> |string,
	customClass?:string
};
export type OptionPropsType = Option;
const option: Option = {
	// option组件
	disabled: false,
};
export default option;
