export type Navbar = {
	safeAreaInsetTop?: boolean,
	placeholder?: boolean,
	fixed?: boolean,
	border?: boolean,
	leftIcon?: string,
	leftText?: string,
	rightText?: string,
	rightIcon?: string,
	title?: string | number,
	bgColor?: string,
	titleWidth?: string | number,
	height?: string | number,
	leftIconSize?: string | number,
	leftIconColor?: string,
	autoBack?: boolean,
	titleStyle?: string | Record<any, any>
};
export type NavbarPropsType = Navbar
const navbar: Navbar = {
	// navbar 组件
	safeAreaInsetTop: true,
	placeholder: false,
	fixed: true,
	border: false,
	leftIcon: 'arrow-left',
	leftText: '',
	rightText: '',
	rightIcon: '',
	title: '',
	bgColor: '#ffffff',
	titleWidth: '400rpx',
	height: '44px',
	leftIconSize: 20,
	leftIconColor: '#303133',
	autoBack: false,
	titleStyle: ''


};
export default navbar;
