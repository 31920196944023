
export type Switch = {
	loading?: boolean,
	disabled?: boolean,
	size?: string|number,
	activeColor?:string,
	inactiveColor?: string,
	modelValue?: boolean,
	activeValue?: boolean,
	inactiveValue?: boolean,
	asyncChange?: boolean,
	space?: string|number,
	customStyle?: Record<string,any> |string
};
export type SwitchPropsType = Switch;

const _switch: Switch = {
	// switch组件
	loading: false,
	disabled: false,
	size: 25,
	activeColor:"#2979ff",
	inactiveColor: "#ffffff",
	modelValue: false,
	activeValue: true,
	inactiveValue: false,
	asyncChange: false,
	space: 0,
};
export default _switch;
