export type CountTo = {
  startVal?: string|number,
  endVal?: string|number,
  duration?: string|number,
  autoplay?: true,
  decimals?: string|number,
  useEasing?: true,
  decimal?: string|number,
  color?:"#606266",
  fontSize?: string|number,
  bold?: false,
  separator?: string,
};
export type CountToPropsType = CountTo
const countTo: CountTo = {
  // countTo组件
  startVal: 0,
  endVal: 0,
  duration: 2000,
  autoplay: true,
  useEasing: true,
  color:"#606266",
  fontSize: 22,
  bold: false

};
export default countTo;
