export type Select = {
	modelValue?: string | number | boolean,
	disabled?: boolean,
	disabledColor?: string,
	placeholder?: string,
	placeholderClass?: string,
	placeholderStyle?: string | Record<string, any>,
	border?: "surround" | "bottom" | "none",
	shape: "square" | "circle",

};
export type SelectPropsType = Omit<
	Select,
	"shape" | "border"
> & {
	shape?: string;
	border?: string
};
const select: Select = {
	// select组件
	disabled: false,
	disabledColor: "#f5f7fa",
	placeholderClass: "input-placeholder",
	placeholderStyle: "color: #c0c4cc",
	border: "surround",
	shape: "square",

};
export default select;
