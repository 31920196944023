export type Keyboard = {
	mode?: "number" | "card" | "car"
	dotDisabled?: boolean
	tooltip?: boolean
	showTitle?: boolean
	title?: string
	showCancel?: boolean
	showConfirm?: boolean
	random?: boolean
	safeAreaInsetBottom?: boolean
	closeOnClickOverlay?: boolean,
	show?: boolean
	overlay?: boolean,
	zIndex?: number | string
	cancelText?: string
	confirmText?: string
	autoChange?: boolean,
	customStyle?: Record<string, string>
};
export type KeyboardPropsType = Omit<Keyboard, "mode"> & {
	mode?: string;
};
const keyboard: Keyboard = {
	// keyboard组件
	mode: "number",
	dotDisabled: false,
	tooltip: true,
	showTitle: true,
	showCancel: true,
	showConfirm: true,
	random: false,
	safeAreaInsetBottom: true,
	closeOnClickOverlay: true,
	show: false,
	overlay: true,
	zIndex: 10075,
	cancelText: '取消',
	confirmText: '确认',
	autoChange: false,
};
export default keyboard;
