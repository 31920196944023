import { reactive } from "vue";
import { deepMerge } from "../../utils";

import form, { type Form, FormPropsType } from "./form";
import formItem, { type FormItem, FormItemPropsType } from "./formItem";
import icon, { type Icon, IconPropsType } from "./icon";
import checkboxGroup, {
	type CheckboxGroup,
	CheckboxGroupPropsType,
} from "./checkboxGroup";
import checkbox, { type Checkbox, CheckboxPropsType } from "./checkbox";
import numberBox, { type NumberBox, NumberBoxPropsType } from "./numberBox";
import radioGroup, {
	type RadioGroup,
	RadioGroupPropsType,
} from "./radioGroup";
import radio, { type Radio, RadioPropsType } from "./radio";
import datetimePicker, { type DatetimePicker, DatetimePickerPropsType } from "./datetimePicker";
import picker, { type Picker, PickerPropsType } from "./picker";
import keyboard, { type Keyboard, KeyboardPropsType } from "./keyboard";
import code, { type Code, CodePropsType } from "./code";
import avatar, { type Avatar, AvatarPropsType } from "./avatar";
import swiper, { type Swiper, SwiperPropsType } from "./swiper";
import swiperIndicator, { type SwiperIndicator, SwiperIndicatorPropsType } from "./swiperIndicator";
import actionSheet, { type ActionSheet, ActionSheetPropsType } from "./actionSheet";
import gap, { type Gap, GapPropsType } from "./gap";
import loadingIcon, { type LoadingIcon, LoadingIconPropsType } from "./loadingIcon";
import text, { type Text, TextPropsType } from "./text";
import navbar, { type Navbar, NavbarPropsType } from "./navbar";
import tabs, { type Tabs, TabsPropsType } from "./tabs";
import badge, { type Badge, BadgePropsType } from "./badge";
import sticky, { type Sticky, StickyPropsType } from "./sticky";
import zIndex, { type ZIndex, ZIndexPropsType } from "./zIndex";
import lazyPicker, { type LazyPicker, LazyPickerPropsType } from "./lazyPicker";
import textarea, { type Textarea, TextareaPropsType } from "./textarea";
import input, { type Input, InputPropsType } from "./input";
import button, { type Button, ButtonPropsType } from "./button";
import calendarMonth, { type CalendarMonth, CalendarMonthPropsType } from "./calendarMonth";
import calendar, { type Calendar, CalendarPropsType } from "./calendar";
import cell, { type Cell, CellPropsType } from "./cell";
import cellGroup, { type CellGroup, CellGroupPropsType } from "./cellGroup";
import codeButton, { type CodeButton, CodeButtonPropsType } from "./codeButton";
import codeInput, { type CodeInput, CodeInputPropsType } from "./codeInput";
import countTo, { type CountTo, CountToPropsType } from "./countTo";
import line, { type Line, LinePropsType } from "./line";
import numberKeyboard, { type NumberKeyboard, NumberKeyboardPropsType } from "./numberKeyboard";
import option, { type Option, OptionPropsType } from "./option";
import overlay, { type Overlay, OverlayPropsType } from "./overlay";
import popup, { type Popup, PopupPropsType } from "./popup";
import select, { type Select, SelectPropsType } from "./select";
import sliders, { type Sliders, SlidersPropsType } from "./sliders";
import statusBar, { type StatusBar, StatusBarPropsType } from "./statusBar";
import _switch, { type Switch, SwitchPropsType } from "./switch";
import transition, { type Transition, TransitionPropsType } from "./transition";
import cascader, { type Cascader, CascaderPropsType } from "./cascader";
import search, { type Search, SearchPropsType } from "./search";
import collapse, { type Collapse, CollapsePropsType } from "./collapse";
import collapseItem, { type CollapseItem, CollapseItemPropsType } from "./collapseItem";
import dragList, { type DragList, DragListPropsType } from "./dragList";
import dragListItem, { type DragListItem, DragListItemPropsType } from "./dragListItem";
import modal, { type Modal, ModalPropsType } from "./modal";
import noticeBar, { type NoticeBar, NoticeBarPropsType } from "./noticeBar";

type Props = {
	form: FormPropsType;
	formItem: FormItemPropsType;
	icon: IconPropsType;
	checkboxGroup: CheckboxGroupPropsType;
	checkbox: CheckboxPropsType;
	numberBox: NumberBoxPropsType;
	radioGroup: RadioGroupPropsType
	radio: RadioPropsType,
	datetimePicker: DatetimePickerPropsType,
	picker: PickerPropsType,
	keyboard: KeyboardPropsType
	code: CodePropsType,
	avatar: AvatarPropsType,
	swiper: SwiperPropsType,
	swiperIndicator: SwiperIndicatorPropsType,
	actionSheet: ActionSheetPropsType,
	gap: GapPropsType,
	loadingIcon: LoadingIconPropsType,
	text: TextPropsType,
	navbar: NavbarPropsType,
	tabs: TabsPropsType,
	badge: BadgePropsType,
	sticky: StickyPropsType,
	zIndex: ZIndexPropsType,
	lazyPicker: LazyPickerPropsType,
	textarea: TextareaPropsType,
	input: InputPropsType,
	button: ButtonPropsType,
	calendarMonth: CalendarMonthPropsType,
	calendar: CalendarPropsType,
	cell: CellPropsType,
	cellGroup: CellGroupPropsType,
	codeButton: CodeButtonPropsType,
	codeInput: CodeInputPropsType,
	countTo: CountToPropsType,
	line: LinePropsType,
	numberKeyboard: NumberKeyboardPropsType,
	option: OptionPropsType,
	overlay:OverlayPropsType,
	popup:PopupPropsType,
	select:SelectPropsType,
	sliders:SlidersPropsType,
	statusBar:StatusBarPropsType,
	switch:SwitchPropsType,
	transition:TransitionPropsType,
	cascader:CascaderPropsType,
	search:SearchPropsType,
	collapse:CollapsePropsType,
	collapseItem:CollapseItemPropsType,
	dragList:DragListPropsType,
	dragListItem:DragListItemPropsType,
	modal:ModalPropsType,
	noticeBar:NoticeBarPropsType
	

};
export type DefaultProps = {
	form: Form;
	formItem: FormItem;
	icon: Icon;
	checkboxGroup: CheckboxGroup;
	checkbox: Checkbox;
	numberBox: NumberBox;
	radioGroup: RadioGroup
	radio: Radio,
	datetimePicker: DatetimePicker,
	picker: Picker,
	keyboard: Keyboard
	code: Code,
	avatar: Avatar,
	swiper: Swiper,
	swiperIndicator: SwiperIndicator,
	actionSheet: ActionSheet,
	gap: Gap,
	loadingIcon: LoadingIcon,
	text: Text,
	navbar: Navbar,
	tabs: Tabs,
	badge: Badge,
	sticky: Sticky,
	zIndex: ZIndex,
	lazyPicker: LazyPicker,
	textarea: Textarea,
	input: Input,
	button: Button,
	calendarMonth: CalendarMonth
	calendar: Calendar,
	cell: Cell,
	cellGroup: CellGroup,
	codeButton: CodeButton,
	codeInput: CodeInput,
	countTo: CountTo,
	line: Line,
	numberKeyboard: NumberKeyboard,
	option: Option,
	overlay:Overlay,
	popup:Popup,
	select:Select,
	sliders:Sliders,
	statusBar:StatusBar,
	switch:Switch,
	transition:Transition,
	cascader:Cascader,
	search:Search,
	collapse:Collapse,
	collapseItem:CollapseItem,
	dragList:DragList,
	dragListItem:DragListItem,
	modal:Modal,
	noticeBar:NoticeBar
};
export type PropsKey = keyof Props;

let oldProps: Props = {
	form,
	formItem,
	icon,
	checkboxGroup,
	checkbox,
	numberBox,
	radioGroup,
	radio,
	datetimePicker,
	picker,
	keyboard,
	code,
	avatar,
	swiper,
	swiperIndicator,
	actionSheet,
	gap,
	loadingIcon,
	text,
	navbar,
	tabs,
	badge,
	sticky,
	zIndex,
	lazyPicker,
	textarea,
	input,
	button,
	calendarMonth,
	calendar,
	cell,
	cellGroup,
	codeButton,
	codeInput,
	countTo,
	line,
	numberKeyboard,
	option,
	overlay,
	popup,
	select,
	sliders,
	statusBar,
	switch:_switch,
	transition,
	cascader,
	search,
	collapse,
	collapseItem,
	dragList,
	dragListItem,
	modal,
	noticeBar
};

const props = reactive<Props>(oldProps);

export const setDefaultProps = (defaultProps: DefaultProps) => {
	oldProps = deepMerge(oldProps, defaultProps) as Props;
	Object.keys(oldProps).forEach((key) => {
		(props as Record<PropsKey, any>)[key as PropsKey] =
			oldProps[key as PropsKey];
	});
};

export const setComponentsProps = (_props: DefaultProps) => {
	const newProps = deepMerge(oldProps, _props) as Props
	Object.keys(oldProps).forEach((key) => {
		(props as Record<PropsKey, any>)[key as PropsKey] =
			newProps[key as PropsKey];
	});
};

export const resetProps = (key: PropsKey) => {
	deepMerge(props, {
		[key]: oldProps[key],
	});
};

export default props;
