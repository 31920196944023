export type Checkbox = {
  name?: string | string | boolean;
  shape?: "square" | "circle";
  size?: string | number;
  checkbox?: boolean;
  disabled?: boolean;
  activeColor?: string;
  inactiveColor?: string;
  iconColor?: string;
  iconSize?: string | number;
  label?: string;
  labelSize?: string | number;
  labelColor?: string;
  labelDisabled?: boolean;
  checked?: boolean;
};
export type CheckboxPropsType = Omit<Checkbox, "shape"> & {
  shape?: string;
};
const checkbox: Checkbox = {
  // checkbox组件
  checkbox: false,
};
export default checkbox;
