export type Code = {
  seconds?: string | number;
  startText?: string;
  changeText?: string;
  endText?: string;
  keepRunning?: boolean;
  uniqueKey?: string;
};
export type CodePropsType = Code
const code: Code = {
  // code组件
  seconds: 60,
  startText:"获取验证码",
  changeText: "X秒重新获取",
  endText:"重新获取",
  keepRunning:false,
};
export default code;
