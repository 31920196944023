
export type NumberKeyboard = {
	mode?: "number"|"card",
	dotDisabled?: false,
	random?: false,
};
export type NumberKeyboardPropsType = Omit<NumberKeyboard,'mode'>&{
	mode?:string
};
const numberKeyboard: NumberKeyboard = {
	// numberKeyboard组件
	mode: "number",
	dotDisabled: false,
	random: false,
};
export default numberKeyboard;
