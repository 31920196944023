type DateMore = {
	date: string;
	className: string;
};

export type CalendarMonth = {
  mode?:'single'|'multiple'|'range',
  startText?:string
  endText?:string,
  rowHeight?:number,
  minDate?:string|number,
  maxDate?:string|number,
  defaultDate?:string| number|Date|Array<string| number|Date>,
  maxCount?:number
  readonly?:boolean
  maxRange?:number,
  rangePrompt?:string,
  showRangePrompt?:boolean
  allowSameDay?:boolean
  sundayStart?:boolean
  nowDate?:string|Date,
  showNowDateDot?:boolean,
  markDateMore?:DateMore[],
  customStyle?: Record<string,any> |string,
  customClass?:string
};
export type CalendarMonthPropsType = Omit<CalendarMonth, "mode"> & {
  mode?: string;
};
const calendarMonth: CalendarMonth = {
  // calendarMonth组件
  mode:'single',
  startText:'开始',
  endText:'结束',
  rowHeight:56,
  minDate:0,
  maxDate:"7258089600000",
  maxCount:Infinity,
  readonly:false,
  maxRange:Infinity,
  showRangePrompt:true,
  allowSameDay:true,
  sundayStart:false,
  nowDate:new Date(),
  showNowDateDot:true
};
export default calendarMonth;
