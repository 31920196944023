export type Sticky = {
	offsetTop?: string | number,
	customNavHeight?: string | number,
	disabled?: boolean,
	bgColor?: string,
	zIndex?: string | number,
	index?: string | number,
	customStyle?: Record<string,any> |string,
	customClass?:string
};
export type StickyPropsType = Sticky
const sticky: Sticky = {
	// sticky组件
	offsetTop: 0,
	// #ifdef H5
	// H5端的导航栏属于“自定义”导航栏的范畴，因为它是非原生的，与普通元素一致
	customNavHeight: 44,
	// #endif
	// #ifndef H5
	customNavHeight: 0,
	// #endif
	disabled: false,
	bgColor: 'transparent',
	zIndex: '',
	index: ''


};
export default sticky;
