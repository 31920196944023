
export type Cell = {
	title?: number | string
	label?: number | string
	value?: number | string
	icon?: string,
	disabled?: boolean,
	border?: boolean,
	center?: boolean,
	url?: string,
	linkType?: string,
	clickable?: boolean,
	isLink?: boolean,
	required?: boolean,
	rightIcon?: string,
	arrowDirection?: string,
	iconStyle?: string | Record<string, any>,
	rightIconStyle?: string | Record<string, any>,
	titleStyle?: string | Record<string, any>,
	size?: string,
	stop?: boolean,
	name?: number | string,
	customStyle?: Record<string,any> |string,
	customClass?:string
};
export type CellPropsType = Cell;
const cell: Cell = {
	// cell组件
	disabled: false,
	border: true,
	center: false,
	linkType: 'navigateTo',
	clickable: false,
	isLink: false,
	required: false,
	rightIcon: 'arrow-right',
	arrowDirection: 'right',
	stop: true,
};
export default cell;
