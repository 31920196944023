/* 
 * 工具类全局配置信息
 */
import {
	API_URL
} from '@/env.js'
const config = {
	// 请求
	xhr: {
		url: [{
			baseUrl: API_URL, //
		}],
		timeOut: 8000, // 请求超时时间
		portStatr: true, // 是否启用端口
	},
	// 文件
	file: {
		size: 20, // 文件上传大小限制
	},
	// 消息提示
	toast: {
		duration: 1500, // 弹窗显示时间
	},
	// 弹窗提示框
	showModal: {
		confirmColor: '#E54E48', // 确定按钮颜色
		cancelColor: '#000000' // 取消按钮颜色
	},

}
export {
	config
}
