import {ThemeType,SizeTypes,ShapeTypes} from '../props'
import { OpenTypeProps } from './types'
export type Button = {
	hairline?: false,
	type?: ThemeType,
	size?: SizeTypes,
	shape?: ShapeTypes,
	plain?: boolean,
	disabled?: boolean,
	loading?: boolean,
	loadingText?: string,
	loadingMode?: 'spinner',
	loadingSize?: 15,
	hoverStopPropagation?: boolean,
	dataName?: string,
	throttleTime?: number,
	hoverStartTime?: number,
	hoverStayTime?: number,
	text?: string,
	icon?: string,
	iconColor?: string,
	color?: string,
	appearance?:string,
	customStyle?: Record<string,any> |string,
	customClass?:string
}&OpenTypeProps;
export type ButtonPropsType = Omit<Button, 'type' | 'shape' | 'size'|'lang'|'formType'|'openType'> & {
	type?: string,
	shape?: string,
	size?: string,
	lang?:string
	formType?:string
	openType?:string
}
const button: Button = {
	// button组件
	hairline: false,
	type: 'info',
	size: 'normal',
	shape: 'square',
	plain: false,
	disabled: false,
	loading: false,
	loadingMode: 'spinner',
	loadingSize: 15,
	hoverStopPropagation: true,
	lang: 'en',
	showMessageCard: false,
	throttleTime: 0,
	hoverStartTime: 0,
	hoverStayTime: 200,

};
export default button;
