import type {ImageModeTypes} from './types'

type easingFunctionType  = 'default'|'linear'|'easeInCubic'|'easeOutCubic'|'easeInOutCubic'
export type Swiper = {
	list?: any[];
	indicator?: boolean,
	indicatorActiveColor?: string,
	indicatorInactiveColor?: string,
	indicatorStyle?: string|Record<string,any>,
	indicatorMode?: 'line'|'dot',
	autoplay?: boolean,
	current?: number,
	currentItemId?: '',
	interval?: number,
	duration?: number,
	circular?: boolean,
	previousMargin?: number,
	nextMargin?: number,
	acceleration?: boolean,
	displayMultipleItems?: 1,
	easingFunction?: easingFunctionType,
	keyName?: string,
	imgMode?: ImageModeTypes,
	height?: number,
	bgColor?: string,
	radius?: number,
	loading?: boolean,
	showTitle?: boolean
};
export type SwiperPropsType = Omit<
	Swiper,
	"indicatorMode"|"imgMode"|"easingFunction"
> & {
	indicatorMode?:string,
	imgMode?: string;
	easingFunction?:string
};
const swiper: Swiper = {
	// swiper组件
	    list:[],
	    indicator: false,
	    indicatorActiveColor: '#FFFFFF',
	    indicatorInactiveColor: 'rgba(255, 255, 255, 0.35)',
	    indicatorStyle: '',
	    indicatorMode: 'line',
	    autoplay: true,
	    current: 0,
	    currentItemId: '',
	    interval: 3000,
	    duration: 300,
	    circular: false,
	    previousMargin: 0,
	    nextMargin: 0,
	    acceleration: false,
	    displayMultipleItems: 1,
	    easingFunction: 'default',
	    keyName: 'url',
	    imgMode: 'aspectFill',
	    height: 130,
	    bgColor: '#f3f4f6',
	    radius: 4,
	    loading: false,
	    showTitle: false

};
export default swiper;
