export type DragList = {
	height?:string|number
	feedbackGenerator?: boolean;
	autoScroll?: boolean;
	longTouchTime?: string | number,
	disabled?:boolean,
	itemHeight?:string|number
	icon?:string,
	iconColor?:string,
	iconSize?:string|number,
	borderBottom?:boolean
	borderColor?:string,
	bgColor?:string
};
export type DragListPropsType = DragList
const dragList: DragList = {
	// dragList组件
	height:'100vh',
	feedbackGenerator:true,
	autoScroll: true,
	longTouchTime: 300,
	disabled:false,
	itemHeight:44,
	icon:'list',
	iconColor:'#333',
	iconSize:16,
	borderBottom:true,
	borderColor:'#eaeaec',
	bgColor:'#fff'
};
export default dragList;
