export type ZIndex = {
	toast?: string | number,
	noNetwork?: string | number,
	popup?: string | number,
	mask?: string | number,
	navbar?: string | number,
	topTips?: string | number,
	sticky?: string | number,
	indexListSticky?: string | number
};
export type ZIndexPropsType = ZIndex
const zIndex: ZIndex = {
	toast: 10090,
	noNetwork: 10080,
	// popup包含popup，actionsheet，keyboard，picker的值
	popup: 10075,
	mask: 10070,
	navbar: 980,
	topTips: 975,
	sticky: 970,
	indexListSticky: 965


};
export default zIndex;
