import App from './App'

// #ifdef VUE3
import utils from '@/utils/index.js';
import { createSSRApp } from 'vue'
import endlessUi from '@/uni_modules/endless-ui'
import { THEME_PRIMARY, API_URL, } from '@/env.js'


export function createApp() {
	const app = createSSRApp(App)
	utils.install(app)
	app.use(endlessUi, {
		input: {
			disabledColor: 'transparent'
		},
		tabs: {
			lineColor: THEME_PRIMARY,
			itemStyle: {
				height: '100rpx'
			}
		},
		form: {
			requiredPosition: 'right'
		},
		radio: {
			activeColor: THEME_PRIMARY
		},
		datetimePicker: {
			confirmColor: THEME_PRIMARY,
			maxDate: new Date().getTime(),
			defaultIndex: [10, 0],
			closeOnClickOverlay: true
		},
		picker: {
			confirmColor: THEME_PRIMARY
		},
		lazyPicker: {
			confirmColor: THEME_PRIMARY
		},
		switch: {
			activeColor: THEME_PRIMARY
		},
		// modal: {
		// 	title: '提示',
		// 	confirmColor: THEME_PRIMARY,
		// 	cancelColor: '#666',
		// 	showCancelButton: true
		// }
	});
	return {
		app,
	}
}
// #endif
