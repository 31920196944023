import {ShapeTypes} from '../props'
export type Modal = {
	title?: string,
	content?: string,
	confirmText?: string,
	cancelText?: string,
	showConfirmButton?: boolean,
	showCancelButton?: boolean,
	confirmColor?: string,
	cancelColor?: string,
	buttonReverse?: boolean,
	zoom?: boolean,
	asyncClose?: boolean,
	closeOnClickOverlay?: boolean,
	negativeTop?: string|number,
	width?: string|number,
	confirmButtonShape?: ShapeTypes
};
export type ModalPropsType = Modal
const modal: Modal = {
	// modal 组件
	confirmText: '确认',
	cancelText: '取消',
	showConfirmButton: true,
	showCancelButton: false,
	confirmColor: '#2979ff',
	cancelColor: '#606266',
	buttonReverse: false,
	zoom: true,
	asyncClose: false,
	closeOnClickOverlay: false,
	negativeTop: 0,
	width: '650rpx'


};
export default modal;
