export type Popup = {
	show?: boolean
	overlay?: boolean
	mode?: "top"|"bottom"|"right"|"left"|"center",
	bgColor?:string,
	duration?: string | number
	closeable?: boolean,
	closeOnClickOverlay?: boolean,
	overlayStyle?:string|Record<string,any>,
	zIndex?:string | number,
	safeAreaInsetBottom?:boolean,
	closeIconPos?: "top-left"|"top-right"|"bottom-left"|"bottom-right",
	round?: string | number,
	zoom?: boolean,
	overlayOpacity?: string | number,
	customStyle?: Record<string,any>|string
};
export type PopupPropsType = Omit<Popup,'mode'|'closeIconPos'>&{
	mode?:string,
	closeIconPos?:string
}
const popup: Popup = {
	// popup组件
	show: false,
	overlay: true,
	mode: "bottom",
	duration: 44,
	closeable: false,
	closeOnClickOverlay:true,
	zIndex:10075,
	safeAreaInsetBottom:true,
	closeIconPos: "top-right",
	round: 0,
	zoom: true,
	overlayOpacity: 0.5,
};
export default popup;
