import {InputCommonProps} from './input'
export type Textarea = InputCommonProps;
export type TextareaPropsType = Omit<Textarea, 'border'|'confirmType'> & {
	border?: string,
	confirmType?:string
}
const textarea: TextareaPropsType = {
	// textarea组件
	modelValue: '',
	placeholder: '',
	placeholderClass: 'textarea-placeholder',
	placeholderStyle: 'color: #c0c4cc',
	height: 70,
	confirmType: 'done',
	disabled: false,
	count: false,
	focus: false,
	autoHeight: false,
	fixed: false,
	cursorSpacing: 0,
	cursor: '',
	showConfirmBar: true,
	selectionStart: -1,
	selectionEnd: -1,
	adjustPosition: true,
	disableDefaultPadding: false,
	holdKeyboard: false,
	maxlength: 140,
	border: 'surround'
};
export default textarea;
