import {
	config
} from '../config/config.js'

export default {
	init(Vue, options) {
		/**
		 * 网络请求
		 * @method $request
		 * @for UinAppUtil
		 * @param {url,data,method,headerType,responseType} 接口地址,携带的参数,请求方式,请求头类型
		 * @return {JSON} 返回 请求结果
		 * responseType:text,arraybuffer
		 */
		Vue.config.globalProperties.$request = function(
				url,
				data = {},
				method = 'POST',
				responseType = 'text',
				api = 0,
				headerType = 1
			) {
				let contentType = ''
				if (headerType == 1) {
					contentType = 'application/json;charset=utf-8'
				} else if (headerType == 2) {
					contentType = 'application/x-www-form-urlencoded'
				} else if (headerType == 3) {
					contentType = 'application/from'
				}
				let token = uni.getStorageSync('token')
				let jixing = 1
				// #ifdef APP-PLUS
				if (uni.getSystemInfoSync().platform == 'ios') {
					jixing = 2
				} else {
					jixing = 1
				}
				// #endif
				let header = {
					'Content-Type': contentType,
					'token': token,
					'duankou': 2,
					'jixing': jixing
				}
				let baseUrl = ''
				baseUrl = config.xhr.url[api].baseUrl + '/'
				if (url.auth && !token) {
					return uni.showModal({
						title: '提示',
						content: '是否前往跳转登录?',
						success: (res) => {
							if (res.confirm) {
								Vue.config.globalProperties.$jump('/pages/public/index')
							}
						}
					})
				} else {
					return new Promise((resolve, reject) => {
						// uni.showLoading({
						// 	title: '加载中....'
						// })
						try {
							uni.request({
								url: baseUrl + url.url,
								data: data,
								header: header,
								method: method,
								responseType: responseType,
								timeout: config.xhr.timeOut,
								success: res => {
									// 请求成功
									uni.hideLoading()
									if (res.data.code == -102) {
										uni.clearStorageSync()
									} else if (res.data.code == -501) {
										Vue.config.globalProperties.$toastApp(res.data.message)
										// Vue.config.globalProperties.$back(1, 500)
										// Vue.config.globalProperties.$jump('/pages/public/wxLogin')
									} else if (res.data.code == -200) {
										uni.removeStorageSync()
										uni.navigateTo({
											url: '/pages/public/index'
										})
										// Vue.config.globalProperties.$jump('/pages/public/wxLogin')
									} else if (res.data.code !== 200) {
										Vue.config.globalProperties.$toastApp(res.data.message)
									}
									//  else if (res.statusCode == 500) {
									// 	Vue.config.globalProperties.$tips('服务端异常')
									// 	console.error(res.data)
									// }
									resolve(res.data)
									// else if (res.data.flag == "998" || res.data.flag == "997") {
									// 	let pages = getCurrentPages();
									// 	let page = pages[pages.length - 1];
									// 	uni.removeStorageSync("token");
									// 	uni.removeStorageSync("userInfo");
									// 	// $goRedirectTo
									// 	Vue.config.globalProperties.$togo('/pages/loginFolder/login')
									// }
								},
								fail: res => {
									uni.hideLoading()
									// console.error("请求失败" + JSON.stringify(res))
									reject(res)
									// console.log(JSON.parse(res.errMsg));
									if (res.errMsg.indexOf('fail') != -1) {
										Vue.config.globalProperties.$toastApp('请求失败')
									} else if (res.errMsg.indexOf('timeout') != -1) {
										Vue.config.globalProperties.$toastApp('请求超时')
									} else if (res.errMsg.indexOf('abort') != -1) {
										Vue.config.globalProperties.$toastApp('请检查网络连接')
									}
								},
							})
						} catch (e) {
							uni.hideLoading()
							reject(e)
							Vue.config.globalProperties.$toastApp('请求异常')
						}
					})
				}
			},

			/**
			 * 获取全局配置信息
			 * @method $getUniAppConfig
			 * @for UinAppUtil
			 */
			Vue.config.globalProperties.$getUniAppConfig = () => {
				// console.log(options)
				return config
			}
		Vue.config.globalProperties.$objToUri = obj => {
			return Object.keys(obj)
				.map(function(k) {
					return k + '=' + obj[k]
				})
				.join('&')
		}
		Vue.config.globalProperties.$objToUriFormat = obj => {
			return Object.keys(obj)
				.map(function(k) {
					return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])
				})
				.join('&')
		}
		Vue.config.globalProperties.$jump = function(url, id, type = 1) {
			if (type == 1) {
				uni.navigateTo({
					url: url
				})
			} else {
				let paramsUrl = `${url}?id=${id}`
				uni.navigateTo({
					url: paramsUrl
				})
			}
			// console.log(url)
		}
		/**
		 * 页面跳转
		 * @method $goTo
		 * @auhor LiJun
		 * @for UinAppUtil
		 * @param {url, data}  跳转链接,跳转的参数
		 */
		Vue.config.globalProperties.$goTo = function(url, options = {}, type = 1) {
			if (type == 1) {
				let goToUrl =
					Object.keys(options).length > 0 ?
					`${url}?${Vue.config.globalProperties.$objToUri(options)}` :
					url
				uni.navigateTo({
					url: goToUrl,
				})

			} else {
				let goToUrl =
					Object.keys(options).length > 0 ?
					`${url}?${Vue.config.globalProperties.$objToUriFormat(options)}` :
					url
				uni.navigateTo({
					url: goToUrl,
				})
			}
			// console.log(url)
		}
		/**
		 * 页面跳转-关闭当前
		 * @method $goRedirectTo
		 * @auhor LiJun
		 * @for UinAppUtil
		 * @param {url, data}  跳转链接,跳转的参数
		 */
		Vue.config.globalProperties.$goRedirectTo = function(url, options = {}) {
			let goToUrl =
				Object.keys(options).length > 0 ?
				`${url}?${Vue.config.globalProperties.$objToUri(options)}` :
				url
			uni.redirectTo({
				url: goToUrl,
			})
		}
		/**
		 * @param {Object} url
		 * 跳转到 tabBar 页面，并关闭其他所有非 tabBar 页面
		 * 路径后不能带参数
		 */
		Vue.config.globalProperties.$switchTab = function(url, index) {
			// console.log(this.$pinia)
			// index && this.$pinia.state.value.customTabbar.appCustomTabbarIndex = index
			uni.switchTab({
				url: url,
			})
		}
		Vue.config.globalProperties.$back = function(delta = 1, seconds = 1000) {
			setTimeout(() => {
				uni.navigateBack({
					delta: delta,
				})
			}, seconds)
		}
		/**
		 * 消息提示
		 * @method $toast
		 * @for UinAppUtil
		 * @param {title, ok, mask, fail}  内容,成功回调,遮罩,失败回调
		 */
		Vue.config.globalProperties.$toast = function(msg, icon = 'none', ok, mask, fail) {
			uni.showToast({
				title: msg,
				icon: icon,
				mask: mask || false,
				duration: config.toast.duration,
				position: 'bottom',
				success: ok,
				fail: fail,
			})
		}
		Vue.config.globalProperties.$toastCheck = function(msg, flag = false, ok, mask, fail) {
			uni.showToast({
				title: msg,
				icon: 'none',
				mask: mask || false,
				duration: config.toast.duration,
				position: 'bottom',
				success: ok,
				fail: fail,
			})
			return flag
		}
		Vue.config.globalProperties.$showModal = function(params) {
			if (!params.title) {
				params.title = "提示"
			}
			params.cancelColor = params.cancelColor || '#4D4D4D',
				params.confirmColor = params.confirmColor
			uni.showModal(params)
		}
		/**
		 * 此方法在APP下使用原生消息提示
		 * @auhor LiJun
		 * @method $toastApp
		 * @for UinAppUtil
		 * @param {msg} 提示内容
		 */
		Vue.config.globalProperties.$toastApp = function(msg) {
			// #ifdef APP-PLUS
			plus.nativeUI.toast(msg)
			// #endif
			// #ifndef APP-PLUS
			Vue.config.globalProperties.$toast(msg)
			// console.warn("$toastApp 此方法APP使用最佳")
			// #endif
		}

		/**
		 * 银行卡转*号
		 * num 需要处理的数值11位
		 * len *转换的长度，默认3个
		 * @method $wxMobileFilter
		 */
		Vue.config.globalProperties.$bankCardFilter = function(value) {
			if (value && value.length > 8) {
				return `${value.substring(0, 4)} ${"*".repeat(value.length - 8).replace(/(.{4})/g, `
				$1 `)}${value.length % 4 ? " " : ""}${value.slice(-4)}`;
			}
			return value;
		}

		/**
		 * 微信小程序手机号码转*号
		 * num 需要处理的数值11位
		 * len *转换的长度，默认3个
		 * @method $wxMobileFilter
		 */
		Vue.config.globalProperties.$wxMobileFilter = function(num, len = 4) {
			return (
				String(num).slice(0, 3) +
				'*'.repeat(len) +
				String(num).slice(len + 3, 11)
			)
		}

		/**
		 * @param {Object} url
		 * 选择图片获取图片路径
		 * 获取图片信息
		 * 注：小程序下获取网络图片信息需先配置download域名白名单才能生效
		 * 返回：
		 * width	图片宽度，单位px	
		 * height	图片高度，单位px	
		 * path	返回图片的本地路径	
		 * orientation	返回图片的方向，有效值见下表	App、小程序
		 * type	返回图片的格式
		 * @method $getImageInfo
		 * 
		 */
		Vue.config.globalProperties.$getImageInfo = function(url) {
			return new Promise((resolve, reject) => {
				uni.getImageInfo({
					src: url, // 从本地相册选择图片或使用相机拍照。
					success: res => {
						resolve(res.path)
						// console.log(`缓存地址：${res.path}`);
					},
					fail: err => {
						reject(err)
						// console.log(`缓存失败：${err.errMsg}`);
					},
				})
			})
		}


		/**
		 * 
		 * 保留小数
		 * 
		 */
		Vue.config.globalProperties.$numFixed = function(nums, decimal = 2) {
			return parseInt(nums).toFixed(decimal)
		}

		/**
		 * @param {Object} data
		 * @method $param
		 * 路由传参转码，结合togo方法一起使用
		 * @auhor 
		 */
		Vue.config.globalProperties.$param = function(data) {
			let url = ''
			for (var k in data) {
				let value = data[k] !== undefined ? data[k] : ''
				url += '&' + k + '=' + encodeURIComponent(JSON.stringify(value))
			}

			return url ? url.substring(1) : ''
		}


		/** 跳转转码
		 * @param {Object} data 跳转过后转码
		 * @method $parse
		 * @auhor 
		 */
		Vue.config.globalProperties.$parse = function(data) {
			return JSON.parse(decodeURIComponent(data))
		}

		/**
		 * 加入时间缓存数据 
		 * @param {Object} key 传进来的数据key
		 * @param {Object} data 传进来的数据
		 * @method $setDataCache
		 * @auhor 
		 */
		Vue.config.globalProperties.$setDataCache = function(key, data) {
			uni.setStorageSync(key, {
				time: Date.now(), //存储的请求成功，保存当前数据时的时间
				data: data
			})
		}

		/**
		 * 身份证号码验证
		 */
		Vue.config.globalProperties.$checkId = function(id) {
			return RegExp(
					/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/)
				.test(id)
		}
		/**
		 * 手机号码验证
		 */
		Vue.config.globalProperties.$checkMobile = function(mobile) {
			return RegExp(/^1[345789]\d{9}$/).test(mobile);
		}

		/**
		 * 获取状态栏高度
		 */
		Vue.config.globalProperties.$statusH = function() {
			let temp
			uni.getSystemInfo({
				success(res) {
					temp = res.statusBarHeight
				}
			})
			return temp
		}



		/**
		 * 判断是否为空对象
		 * @method $isEmpty
		 * @for util
		 * @param {value} 对象
		 * @example  如果为空返回真，否则返回假
		 * @return {Boolean} 返回 
		 */
		Vue.config.globalProperties.$isEmpty = function(data) {
			if (Object.keys(data).length == 0) {
				return true
			} else {
				return false
			}
		};

		/**
		 * 分页函数
		 * @method $pageLoad
		 * @param {loadList, addList, pageNo , pageSize, cache}
		 */
		Vue.config.globalProperties.$pageLoad = function(loadList, addList, pageNo = 1, pageSize = 10, cache = true) {
			let loadStatus = false
			// 加载内容是否为空
			if (addList.length != 0 || !cache) {
				// 判断是否为第一次加载 or 第一页加载未满
				if (loadList.length == 0 || loadList.length < pageSize) {
					// 判断数据是否有变动
					if (loadList.length != addList.length || !cache) {
						loadList.splice(0, loadList.length)
						loadList.push(...addList)
						// loadStatus = true
					}
				} else {
					var [pageNo, more] = [pageNo, loadList.length % pageSize]
					// 目前加载的页数是否全部为满页
					if (more == 0) {
						loadList.push(...addList)
						loadStatus = true
					} else {
						if (more < addList.length) {
							loadStatus = true
							// 加载剩余的数据
							for (let i = more; i < addList.length; i++) {
								loadList.push(addList[i])
							}
						}
					}
				}
				// 加载数据是否够一页
				if (addList.length == pageSize) {
					pageNo++
				}
				return {
					loadStatus: loadStatus,
					list: loadList,
					pageNo: pageNo,
				}
			} else {
				return {
					loadStatus: false,
					list: loadList,
					pageNo: pageNo,
				}
			}
		};

		/**
		 * 停止下拉刷新动画
		 */
		Vue.config.globalProperties.$stopPull = function() {
			setTimeout(() => {
				uni.stopPullDownRefresh()
			}, 500)
		}

		/**
		 * 数组对象除重
		 */
		Vue.config.globalProperties.$mergeArray = function(arr1, arr2) {
			// 克隆
			const cloneArr1 = arr1.slice(0);
			let v;
			for (let i = 0; i < arr2.length; i++) {
				v = arr2[i];
				// 能找到相同 id 属性值的数据则进入判断
				if (~cloneArr1.findIndex((el) => el.id === v.id)) {
					continue;
				}
				cloneArr1.push(v);
			}
			return cloneArr1;
		}

		/*
		1、 format 格式必须以 YYYY(年) MM(月) DD(日) hh(时) mm(分) ss(秒) 自由拼接，
			可自定义连接符，例如：YYYY-MM-DD hh:mm:ss
		2、 date 可传入两种格式 (Date格式 、 时间戳格式)
		*/
		// 给Date类添加了一个新的实例方法format
		/** 
		 * 时间戳转化为年 月 日 时 分 秒 
		 * number: 传入时间戳 
		 * format：返回格式，支持自定义，但参数必须与formateArr里保持一致 
		 * 例子 $format(date,'Y-M-D h:m:s') //2022-10-10 10:10:10
		 */
		Vue.config.globalProperties.$format = function(number, format) {

			var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
			var returnArr = [];
			var date = new Date(number * 1000);
			returnArr.push(date.getFullYear());
			returnArr.push(formatNumber(date.getMonth() + 1));
			returnArr.push(formatNumber(date.getDate()));
			returnArr.push(formatNumber(date.getHours()));
			returnArr.push(formatNumber(date.getMinutes()));
			returnArr.push(formatNumber(date.getSeconds()));
			for (var i in returnArr) {
				format = format.replace(formateArr[i], returnArr[i]);
			}
			return format;
		}

		//数据转化  
		function formatNumber(n) {
			n = n.toString()
			return n[1] ? n : '0' + n
		}

	}
}
