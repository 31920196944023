export type DragListItem = {
	index?:string|number
	disabled?:boolean
	label?:string|number
};
export type DragListItemPropsType = DragListItem
const dragListItem: DragListItem = {
	// dragListItem组件
	disabled:false
};
export default dragListItem;
