export type Gap = {
	bgColor?: string,
	height?: number|string,
	marginTop?:  number|string,
	marginBottom?:  number|string,
	customStyle?: Record<string,any>
};
export type GapPropsType = Gap
const gap: Gap = {
	// gap组件
	bgColor: 'transparent',
	height: 20,
	marginTop: 0,
	marginBottom: 0,
	customStyle: {}

};
export default gap;
